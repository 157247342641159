import { sprintf } from 'sprintf-js';
let overrides = {};
export function overrideTranslations(mapping) {
    overrides = mapping;
}
export function __z(key, suggest, ...replacements) {
    // Check internal overrides, try global __z or fallback to suggested text
    const translation = overrides[key] || window.__z?.(key, suggest) || suggest;
    // Apply replacements if there are any
    try {
        return replacements.length > 0 ? sprintf(translation, ...replacements) : translation;
    }
    catch (error) {
        console.error('Error setting replacements on translated text.', {
            'error': error,
            'key': key,
            'suggested': suggest,
            'translation': translation,
            'replacements': replacements
        });
        return replacements.length > 0 ? sprintf(suggest, ...replacements) : suggest;
    }
}
export const currentUserLocale = () => window.zumba_localization?.locale || 'en-US';
export const isoCountryCode = () => window.zumba_localization?.country || 'US';
export const prefixLocaleURL = (url) => '/' + currentUserLocale() + url;
export function validateTranslations(supplied, defaults) {
    const valid = Object.keys(defaults).every((key) => key in supplied);
    if (!valid) {
        const missing = Object.keys(defaults).filter((key) => !(key in supplied));
        console.error(`Missing translation keys: ${missing.join(', ')}`);
    }
    return valid;
}
/**
 * Helper method
 */
export function validator(defaults) {
    return function (value) {
        return validateTranslations(value, defaults);
    };
}
