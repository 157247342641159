import { readPageData } from "@ts/Util/page";
import { get } from "@ts/Util/http";
import { prefixLocaleURL } from "@ts/Util/i18n";
export const csrfToken = () => readPageData('csrfToken') || '';
export const isUserAuthenticated = () => !!readPageData('authenticated');
export const setCsrfToken = (csrfToken) => {
    const _window = window;
    _window.zumba_jsViewVars.csrfToken = csrfToken;
};
export const refreshCsrfToken = () => {
    const inputs = Array.from(document.querySelectorAll('input[name="data[_Token][key]"]')).filter(input => input.type === 'hidden');
    const _window = window;
    return get(prefixLocaleURL('/user/refresh_csrf'), csrfToken())
        .then(res => res.json())
        .then(data => {
        if (_window.zumba_jsViewVars) {
            _window.zumba_jsViewVars.csrfToken = data.csrfToken;
        }
        inputs.forEach(input => {
            input.value = data.csrfToken;
        });
        return data.csrfToken;
    });
};
