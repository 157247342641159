import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "toggle" };
const _hoisted_2 = {
    key: 0,
    class: "drawer-title"
};
const _hoisted_3 = {
    key: 1,
    class: "p-caption text-light-secondary drawer-subhead"
};
import { computed, ref, useSlots } from 'vue';
import { SwipeDirection, useSwipe } from '@vueuse/core';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MobileDrawer',
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        subhead: {
            type: String,
            default: '',
        },
        drawerHandleVisible: {
            type: Boolean,
            default: true
        },
        scrollable: {
            type: Boolean,
            required: false,
        },
        backgroundOverlay: {
            type: Boolean,
            required: false,
        },
        closeWhenClickOnBackground: {
            type: Boolean,
            default: false
        },
        alignCenter: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:open'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const classes = computed(() => {
            return {
                'drawer': true,
                'open': props.open,
                'drawer--no-handle': !props.drawerHandleVisible,
            };
        });
        const swipeArea = ref(null);
        useSwipe(swipeArea, {
            threshold: 30,
            onSwipeEnd(e, direction) {
                if (direction === SwipeDirection.DOWN) {
                    emit('update:open', false);
                }
            }
        });
        const chestHeight = computed(() => {
            return !slots['toggle-header'] ? '2rem' : '3.4rem';
        });
        const toggleDrawer = () => {
            emit('update:open', !props.open);
        };
        const handleBackgroundClick = (e) => {
            if (e.target?.classList.contains('drawer-bg') && props.open && props.closeWhenClickOnBackground) {
                toggleDrawer();
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({ 'drawer-bg': __props.backgroundOverlay, 'open': __props.open }),
                onClick: handleBackgroundClick
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["chest", {
                            'chest--handle-hidden': !__props.drawerHandleVisible
                        }]),
                    style: _normalizeStyle(`height: ${chestHeight.value}`)
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(classes.value)
                    }, [
                        _createElementVNode("div", {
                            ref_key: "swipeArea",
                            ref: swipeArea,
                            href: "#",
                            class: "toggle-container",
                            onClick: _withModifiers(toggleDrawer, ["prevent"])
                        }, [
                            _createElementVNode("a", _hoisted_1, [
                                _renderSlot(_ctx.$slots, "toggle-header")
                            ])
                        ], 512),
                        (__props.title || __props.subhead)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["drawer-header", { 'center-aligned': __props.alignCenter }])
                            }, [
                                _renderSlot(_ctx.$slots, "header-action"),
                                (__props.title)
                                    ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(__props.title), 1))
                                    : _createCommentVNode("", true),
                                (__props.subhead)
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(__props.subhead), 1))
                                    : _createCommentVNode("", true)
                            ], 2))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass({ 'scrollable': __props.scrollable })
                        }, [
                            _renderSlot(_ctx.$slots, "body")
                        ], 2)
                    ], 2)
                ], 6)
            ], 2));
        };
    }
});
