import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withKeys as _withKeys, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "modal-mobile-drawer"
};
import { onMounted, onUnmounted, computed, ref } from 'vue';
import CloseSVG from "@bx-icons/regular/bx-x.svg";
import MobileDrawer from '@components/Menus/MobileDrawer.vue';
import { isMobileReactive } from '@ts/Util/responsiveness';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Modal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'light',
            validator: (value) => {
                // You can add more modes types here
                return [
                    'light',
                    'dark',
                    'youtube'
                ].includes(value);
            }
        },
        customStyles: {
            type: Object,
            default: () => { }
        },
        keepAlive: {
            type: Boolean,
            default: false
        },
        closeWhenTypeEsc: {
            type: Boolean,
            default: false
        },
        closeWhenClickOnBackground: {
            type: Boolean,
            default: true
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        enableScroll: {
            type: Boolean,
            default: false
        },
        mobileDrawer: {
            type: Boolean,
            default: false
        },
        showNavbar: {
            type: Boolean,
            default: false
        }
    },
    emits: ['closed'],
    setup(__props, { emit: __emit }) {
        const swipeArea = ref(null);
        const props = __props;
        const emit = __emit;
        const emitClosedOnYoutubeModeListener = (e) => {
            if (props.show && props.mode === 'youtube' && e.key === 'Escape' && props.closeWhenTypeEsc) {
                emit('closed');
            }
        };
        onMounted(() => {
            document.addEventListener('keyup', emitClosedOnYoutubeModeListener);
        });
        onUnmounted(() => document.removeEventListener('keyup', emitClosedOnYoutubeModeListener));
        const handleBackgroundClick = (e) => {
            if (e.target?.classList.contains('modal-bg') && props.show && props.closeWhenClickOnBackground) {
                emit('closed');
            }
        };
        // this one is introduced to avoid double rendering of a modal content and causing error with PayPal buttons in the mobile drawer mode
        const isMobileDrawer = computed(() => {
            const isMobile = isMobileReactive();
            return isMobile.value && props.mobileDrawer;
        });
        return (_ctx, _cache) => {
            return (__props.keepAlive || __props.show)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref_key: "swipeArea",
                    ref: swipeArea,
                    class: _normalizeClass(["modal-bg", {
                            [__props.mode]: true,
                            'modal--with-scroll': props.enableScroll,
                            'has-mobile-drawer': props.mobileDrawer
                        }]),
                    style: _normalizeStyle(props.showNavbar ? 'z-index: 1004;' : 'top: 0;'),
                    onKeyup: _cache[2] || (_cache[2] = _withKeys(($event) => (emit('closed')), ["esc"])),
                    onClick: handleBackgroundClick
                }, [
                    (!isMobileDrawer.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["modal", {
                                    [__props.mode]: true,
                                    'modal--no-padding': __props.noPadding,
                                    'modal--with-scroll': props.enableScroll,
                                    'no-mobile-drawer': !props.mobileDrawer,
                                    'has-mobile-drawer': props.mobileDrawer
                                }]),
                            style: _normalizeStyle(props.customStyles?.modal),
                            tabindex: "-1",
                            role: "dialog"
                        }, [
                            _renderSlot(_ctx.$slots, "default"),
                            (__props.showCloseButton)
                                ? (_openBlock(), _createBlock(_unref(CloseSVG), {
                                    key: 0,
                                    class: _normalizeClass(["modal__close", {
                                            [__props.mode]: true
                                        }]),
                                    style: _normalizeStyle(props.customStyles?.modal__close),
                                    onClick: _cache[0] || (_cache[0] = ($event) => (emit('closed')))
                                }, null, 8, ["style", "class"]))
                                : _createCommentVNode("", true)
                        ], 6))
                        : _createCommentVNode("", true),
                    (isMobileDrawer.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(MobileDrawer, {
                                open: true,
                                "drawer-handle-visible": __props.showCloseButton,
                                scrollable: props.enableScroll,
                                "onUpdate:open": _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('closed')))
                            }, {
                                body: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "default")
                                ]),
                                _: 3
                            }, 8, ["drawer-handle-visible", "scrollable"])
                        ]))
                        : _createCommentVNode("", true)
                ], 38)), [
                    [_vShow, __props.show]
                ])
                : _createCommentVNode("", true);
        };
    }
});
