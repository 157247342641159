import { refreshCsrfToken } from "@ts/Util/security";
const makeRequest = async (method, url, csrfToken, args, headers) => {
    // eslint-disable-next-line no-undef
    const fetchConfig = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Http-Request-Type': 'blt',
            'X-CSRF-Token': csrfToken,
            ...headers
        }
    };
    if (args instanceof FormData) {
        delete fetchConfig.headers['Content-Type'];
    }
    if (args && method.toLowerCase() == 'get') {
        const searchParams = new URLSearchParams();
        Object.keys(args).forEach(k => {
            if (Array.isArray(args[k])) {
                args[k].forEach(v => searchParams.append(`${k}[]`, v));
            }
            else {
                searchParams.append(k, args[k]);
            }
        });
        url.search = (searchParams).toString();
    }
    else if (args && (method.toLowerCase() == 'post' || method.toLowerCase() == 'put')) {
        fetchConfig.body = args instanceof FormData ? args : JSON.stringify(args);
    }
    let request = new Request(url.toString(), fetchConfig);
    let response = await fetch(request);
    try {
        const error = await response.clone().json();
        if (!response.ok && error.message.includes('CSRF')) {
            // Retry the request if the error is a CSRF error
            fetchConfig.headers['X-CSRF-Token'] = await refreshCsrfToken();
            request = new Request(url.toString(), fetchConfig);
            response = await fetch(request);
        }
    }
    catch {
        console.error('Error parsing response');
    }
    return response;
};
const urlFromString = (urlString) => {
    const base = (urlString.indexOf('://') === -1) ? window.location.origin : undefined;
    if (base) {
        return new URL(urlString, base);
    }
    return new URL(urlString);
};
/**
 * Perform a GET request
 *
 * @param urlString
 * @param query
 * @returns
 */
export const get = (urlString, csrfToken, query, headers) => {
    return makeRequest('GET', urlFromString(urlString), csrfToken, query, headers);
};
/**
 * Perform a POST request
 */
export const post = (urlString, csrfToken, body) => {
    return makeRequest('POST', urlFromString(urlString), csrfToken, body);
};
/**
 * Perform a PUT request
 */
export const put = (urlString, csrfToken, body) => {
    return makeRequest('PUT', urlFromString(urlString), csrfToken, body);
};
export const redirect = (urlFragment = '/') => {
    const _window = window;
    const urlParams = _window.location.search;
    if (urlParams) {
        urlFragment += urlParams;
    }
    _window.location = urlFragment;
};
export const redirectWithoutParams = (url = '/') => {
    const _window = window;
    _window.location = url;
};
export const getQueryParam = (name) => {
    const _window = window;
    const urlParams = new URLSearchParams(_window.location.search);
    return urlParams.get(name);
};
export const vPrefixHttp = {
    updated: (el) => {
        const PROTOCOL_START = /^https?:\/\//i;
        if (el.value &&
            !PROTOCOL_START.test(el.value) &&
            'http://'.indexOf(el.value) === -1 &&
            'https://'.indexOf(el.value) === -1) {
            el.value = 'http://' + el.value;
        }
    }
};
