import { ref } from 'vue';
export function remToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
export function isMobile() {
    return window.innerWidth <= remToPixels(48);
}
export function isDesktop() {
    return window.innerWidth > remToPixels(48);
}
const isMobileRef = ref(isMobile());
let matchMedia = null;
export function isMobileReactive() {
    if (!matchMedia) {
        matchMedia = window.matchMedia('(max-width: 48rem)');
        matchMedia.addEventListener('change', (e) => isMobileRef.value = e.matches);
    }
    return isMobileRef;
}
export function isSafari() {
    return navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
}
export function isIOS() {
    return navigator.userAgent.match(/iP(ad|hone|od)/i) !== null;
}
export function isAndroid() {
    return navigator.userAgent.match(/Android/i) !== null;
}
export function isWebview() {
    const rules = [
        // if it says it's a webview, let's go with that
        'WebView',
        // iOS webview will be the same as safari but missing "Safari"
        '(iPhone|iPod|iPad)(?!.*Safari)',
        // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
        'Android.*Version/[0-9]\.[0-9]',
        // Also, we should save the wv detected for Lollipop
        // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
        'Android.*wv',
        // old chrome android webview agent
        'Linux; U; Android'
    ];
    const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');
    return navigator.userAgent.match(webviewRegExp) !== null;
}
