export const readPageData = (key) => {
    const _window = window;
    return _window.zumba_jsViewVars ? _window.zumba_jsViewVars[key] : undefined;
};
export const loadScript = (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.onload = () => resolve();
        script.onerror = () => reject();
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
    });
};
export const goToUrl = (url) => window.document.location = url;
